import {
  Component, Input, Output, EventEmitter
} from '@angular/core';
import {
  UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators
} from '@angular/forms';
import { UserService } from 'src/app/_core/services/user/user.service';
import { UserDataService } from 'src/app/_core/authentication/user-data.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { CuitChangedService } from 'src/app/_core/services/common/cuit-changed/cuit-changed.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { lookUp, getErrorCode } from 'src/app/_core/dictionary/error.dictionary';
import { REGEX } from '../../helpers/applicationConstants';

@Component({
  selector: 'app-validate-phone',
  templateUrl: './validate-phone.component.html',
  styleUrls: ['./validate-phone.component.scss']
  })
export class ValidatePhoneComponent {
  /**
   * Use in order to change page design.
   */
  @Input() forcePhoneInput: boolean;

  /**
   * Use in order to redirect user after successful login
   */
  @Input() returnUrl: string;

  /**
   * Emits event in order to close dialog.
   */
  @Output() close = new EventEmitter<string>();

  /**
   * Phone Form. Controls are stored in this variable.
   */
  public phoneForm: UntypedFormGroup;

  /**
   * Show loading spinner.
   */
  public showSpinner: boolean;

  /**
   * Show loading spinner for Reenviar button.
   */
  public showSpinnerReenviar: boolean;

  /**
   * Mask used for phone field.
   */
  public mask = '0000000000';

  /**
   * Used in order to cancel request when component is destroyed.
   */
  public ngUnsubscribe = new Subject();

  /**
   * Variables for first step of validation.
   */
  public messageOne = `¡Hola, ${this.userDataService.getUserName()}!`;

  // eslint-disable-next-line max-len
  public messageTwo = 'Con motivo de garantizarle mayor seguridad en el uso de nuestra app AGD Agro, solicitamos nos facilite su número de telefonía móvil. ¡Muchas gracias!';

  public firstStep = true;

  public submitButtonLabel = 'Continuar';

  /**
   * Indicates if user don't want this message any more.
   */
  public noMoreMessages = false;

  /**
   * Used for error messages.
   */
  public showErrorMessage = false;

  /**
   * Error message for back-end responses.
   */
  public errorMessage: string;

  private messageOk = 'Gracias! Su número de telefonía móvil se registró correctamente.';

  constructor(
    public formBuilder: UntypedFormBuilder,
    public userService: UserService,
    public cuitChangedService: CuitChangedService,
    public userDataService: UserDataService,
    public router: Router,
    public dialog: MatDialog,
    public snackBar: MatSnackBar
  ) {
    this.phoneForm = this.formBuilder.group({
      phone: new UntypedFormControl('', Validators.compose([
        Validators.required,
        Validators.minLength(10),
        Validators.maxLength(10),
        Validators.pattern(REGEX.PHONE)
      ]))
    });
  }

  /**
   * First step: Saves user's phone number.
   * Second step: Sends code for its validation.
   */
  public save(): void {
    this.showSpinner = true;
    // First step of validation. Saves phone.
    if (this.firstStep) {
      const phone = (this.noMoreMessages) ? '---' : this.phoneForm.controls.phone.value;

      this.userService.setUserPhone(phone).subscribe(
        (res: any) => {
          this.showSpinner = false;
          // if user don't want to be requested about its phone, we save '---' as phone value and close dialog.
          if (this.noMoreMessages) {
            this.close.emit('Gracias! No volveremos a mostrar este mensaje.');
          } else {
            // variables are set for second step.
            this.firstStep = false;
            this.mask = '000000';
            this.phoneForm = this.formBuilder.group({
              code: new UntypedFormControl('', Validators.compose([
                Validators.required,
                Validators.minLength(6),
                Validators.maxLength(6),
                Validators.pattern(REGEX.SMS_CODE)
              ]))
            });
            this.messageOne = '¡Recibiste un código de 6 dígitos por SMS!';
            this.messageTwo = 'Por favor, ingresalo para verificar que eres tú:';
            this.submitButtonLabel = 'Validar';
            this.showErrorMessage = false;
          }
        },
        (err: any) => {
          const errorCode = getErrorCode(err);
          this.errorMessage = lookUp(errorCode);
          this.showErrorMessage = true;
          this.showSpinner = false;
        }
      );
    }
    // Second step of validation. Sends code for its validation.
    else {
      this.showSpinner = false;
      const code = this.phoneForm.controls.code.value;
      this.userService.validatePhoneCode(this.userDataService.getActiveUserId(), code).subscribe(
        (res: any) => {
          this.showSpinner = false;
          if (this.forcePhoneInput) {
            this.userDataService.removeVerifyPhoneItem();
            // we add a delay in order to have a clean navigation.
            setTimeout(() => {
              this.router.navigate([this.returnUrl || '/dashboard'], { state: { action: 'showSnackBar', message: this.messageOk } });
            }, 100);
          } else {
            this.close.emit(this.messageOk);
          }
        },
        (err: any) => {
          const errorCode = getErrorCode(err);
          this.errorMessage = lookUp(errorCode);
          this.showErrorMessage = true;
          this.showSpinner = false;
        }
      );
    }
  }

  /**
   * Clears messages.
   */
  public clearMessages() {
    this.showErrorMessage = false;
  }

  /**
   * Sends a new validation code via SMS to user.
   */
  public sendNewCode() {
    this.showSpinnerReenviar = true;
    this.userService.resendPhoneCode(this.userDataService.getActiveUserId()).subscribe(
      (res: any) => {
        this.messageOne = '¡Enviamos un nuevo código de 6 dígitos por SMS!';
        this.showErrorMessage = false;
        this.showSpinnerReenviar = false;
      },
      (err: any) => {
        const errorCode = getErrorCode(err);
        this.errorMessage = lookUp(errorCode);
        this.showErrorMessage = true;
        this.showSpinnerReenviar = false;
      }
    );
  }

  /**
   * Determines whether a field has a valid value or not.
   * @param fieldName Field name based on the FormControl property.
   * @returns true if valid.
   */
  public isValid(fieldName: string): boolean {
    const field = this.phoneForm.controls[fieldName];
    return field.valid;
  }

  /**
   * Determines whether a field has an invalid value or not.
   * @param fieldName Field name based on the FormControl property.
   * @returns true if invalid.
   */
  public isInvalid(fieldName: string): boolean {
    const field = this.phoneForm.controls[fieldName];
    return field.invalid && field.touched && field.value !== '';
  }

  /**
   * Determines whether the submit button is enabled or not based
   * on the phone form status.
   * @returns true if the log in form is valid.
   */
  public buttonEnabled(): boolean {
    return (this.phoneForm.valid || this.noMoreMessages);
  }

  /**
   * Process checkbox change event.
   * @param event event
   */
  public checkboxChange(event: any) {
    this.noMoreMessages = event.target.checked;
    if (this.noMoreMessages) {
      this.phoneForm.disable();
    } else {
      this.phoneForm.enable();
    }
  }
}
